import React, { useState, useEffect, useRef} from 'react';
import * as yup from "yup";
import { regex } from "../../helpers/Validator";
import { useFormik } from "formik";
// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Grid, 
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    makeStyles,
    Box,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    FormControlLabel,
    DialogTitle,
    DialogActions,
    InputAdornment,
    Paper
} from '@material-ui/core';

import AutocompleteFromEntity from '../controls/AutocompleteFromEntity';
import AutocompleteFromEntityRegistro from '../controls/AutocompleteRegistroFromEntity';
import AutocompleteFromEntityRegistroStalls from '../controls/AutocompleteRegistroFromEntityStalls';
import { 
    corregirEspaciado
} from "../../helpers/Validator";

import { getEmpresasAdmin } from '../../core/superAdminServices/empresaAPI'
import { changeTipoDigitacionState, isAuthenticated, refreshDataSession } from "../../core/sessionAPI";
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

// Custom components
import { showLoader, hideLoader } from '../shared/Loader';
import ShowAlert from '../shared/Snackbar';
import "../../../src/App.css";
import { DialogValidarCambiarCedula } from './DialogValidarCambiarCedula';

// Servicios
import { createSupervisor, updateSupervisor, confirmActivateEmail } from '../../core/apiSupervisores';
import { getRolesEvento, getPuestosEventoRegistro, getPuesto } from '../../core/apiRolesEvento';
import { getDepartamentosMunicipiosGerente, getGerentes, getGerenteCiudad } from '../../core/apiGerentes';
import { getEmpresasOperadorasByEvento } from '../../core/apiEmpresaOperadora';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Edit from '@material-ui/icons/Edit';
import { uploadFile } from '../../core/apiControls';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormularioSupervisores ({open, close, idEvento, eventoName, modo, data, tiposPago = [], proveedores = []})
{
    const classes = useStyles();
    const [rolesEvento, setRolesEvento ] = useState([]);
    const [documentoToValidate, setDocuementoToValidate] = useState('')
    const [empresaSelected, setEmpresaSelected] = useState();
    const [EOselected, setEOselected] = useState('');
    const [documentType, setDocumentType] = useState('C.C');

    const [roleSelected, setRoleSelected] = useState();
    const [entregaCheck, setEntregaCheck] = useState(false);
    const [recolectaCheck, setRecolectaCheck] = useState(false);
    const [CADCheck, setCADCheck] = useState(false);
    const [tipoContribuidorSelected, setTipoContribuidorSelected] = useState(true);
    const [empresas, setEmpresas ] = useState([]);
    const [snackbarProps, setSnackbarProps] = useState();
    const [unPuestoRol, setUnPuestoRol] = useState();
    const [puestosOcupados, setPuestosOcupados ] = useState([]);

    const [departamentos, setDepartamentos] = useState([]);
    const [departamentoFacturacion, setDepartamentoFacturacion] = useState();
    const [reloadCiudadFacturacion, setReloadCiudadFacturacion] = useState(0);

    const [proveedorSelected, setProveedorSelected] = useState();

    const [ciudades, setCiudades] = useState([]);
    const [ciudadFacturacion, setCiudadFacturacion] = useState();
    
    const [puestos, setPuestos] = useState([]);
    const [gerente, setGerente] = useState(null);
    const [empresasOperadoras, setEmpresasOperadoras] = useState([]);    
    const [proyectos, setProyectos] = useState(null);
    const [serviciosSelected, setServiciosSelected] = useState([])    
    const [isAdmin, setIsAdmin] = useState(false);
    const [gerenteSelected, setGerenteSelected] = useState();
    const [gerentes, setGerentes] = useState([]);
    const [tipoPagoSelected, setTipoPagoSelected] = useState();
    const [rolActual, setRolActual] = useState(null);
    const [isDigitacionManualCheck, setIsDigitacionManualCheck] = useState(false)
    const [confirmacionDocumento, setConfirmacionDocumento] = useState(false);
    /* Textos del título/botón del formulario */

    const [reloadCities, setReloadCities] = useState(0);
    const [reloadStation, setReloadStation] = useState(0);

    const [modalValidarCambiarCedula, setModalValidarCambiarCedula] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [sucursalGasto, setSucursalGasto] = useState(null);

    const isSubmitting = useRef(false);
    const inputFileRef = useRef();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if( !file.name.endsWith('.pdf')) return setSnackbarProps("e" + "Solo se permiten archivos PDF");
        const fileSizeInBytes = file.size;
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
        if(fileSizeInMB.toFixed(2)<5){
            setSelectedFile(file);
        }else{
            setSnackbarProps("e" + "El RUT no puede exceder un tamaño maximo de 5MB, reduzca el tamaño del archivo");
        }
    };

    const resetForm = () =>{
        setReloadStation(0);
        setReloadCities(0);      
        setServiciosSelected([]);
        setProyectos(null);
        formik.handleReset();
        setEntregaCheck(false);
        setCADCheck(false)
        setRecolectaCheck(false);
        setRoleSelected();
        setEmpresaSelected();
        setEOselected();
        setGerenteSelected();
        setTipoPagoSelected();
        setRolActual(null);  
        setPuestosOcupados([]);
        setPuestos([])
        setDocumentType('C.C')
        setSucursalGasto(null)
        close();
    }

    const loadEmpresaOperadora = async () => {
        showLoader();
        const res = await getEmpresasOperadorasByEvento(idEvento);
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEmpresasOperadoras([]);
        }else{
            setEmpresasOperadoras(res.data);
            setEOselected(res.data && res.data.length > 0 ?  res.data[0]._id:null);
        }
        hideLoader();
    }

    const loadDataGerentes = async () => {
        const {user} = isAuthenticated()
        if(user.role === 2){   
            showLoader();
            const res = await getGerentes(user.empresa);
            if (res.error){
                setSnackbarProps("e" + res.error);
                setGerentes([]);
            }else{
                setGerentes(res.data);
            }
            hideLoader();
        }
    }

    const getDptoMunGerente = async () => {
        const {user} = isAuthenticated();
        const resp = await getDepartamentosMunicipiosGerente(user._id)
        if (resp.error){
            setSnackbarProps("e" + resp.error);
            setGerente([]);
        }else{
            setGerente(resp.data[0]);            
        }
    }

    const handleCambiarCedula = () =>{
        setModalValidarCambiarCedula(false);
    }

    const closeModalCedula = () =>{
        setModalValidarCambiarCedula(false);
    }

    const closeClear = () => {
        setReloadStation(0)
        setReloadCities(0)
        setServiciosSelected([])
        setProyectos(null)
        resetForm();
        //close();
    }

    const loadDataRol = async () => {
        showLoader();
        const res = await getRolesEvento(idEvento);
        if (res.error){
            setSnackbarProps("e" + res.error);
            setRolesEvento([]);
        }else{
            setRolesEvento(res.data);
        }
        hideLoader();
    }

    const loadCompanies = async () =>
    {
        showLoader();
        const res = await getEmpresasAdmin();

        if (res.error){
            setSnackbarProps("e" + res.error)
            setEmpresas([]);
        }else{
            setEmpresas(res.data);
            setEmpresaSelected(res.data && res.data.length > 0 ?  res.data[0]._id:null);
        }
        hideLoader();
    }

    const handleButtonEditCedula = async () => {
        setModalValidarCambiarCedula(true);
    }

    const newCedula = async (newValue) =>{
        formik.setFieldValue("documento", newValue);
    }

    const loadPuestosRol = async (rol) =>
    {
        showLoader()
        const res = await  getPuestosEventoRegistro(idEvento, rol);
        hideLoader()
        if (res.error){
            setSnackbarProps("e" + res.error)
            setPuestosOcupados([]);
        }else{
            setPuestosOcupados(res.data);
        }
    }

    useEffect(() => {
        const updatedCities = ciudades.filter( ({departamento}) => 
            departamentos.find( ({_id}) => _id === departamento )
        )
        setCiudades(updatedCities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departamentos]) 

    // Actualizar puestos cuando se borra una ciudad
    useEffect(() => {
        if(Array.isArray(puestos) && puestos?.length > 0){
            const updatedStands = puestos.filter( ({municipio}) => 
                ciudades.find( ({_id}) => _id === municipio )
            )
            setPuestos(updatedStands)
            setReloadStation(prev => prev * Math.random())
        }else {
            const updatedStands =  ciudades.find( ({_id}) => _id === puestos.municipio) 
            setPuestos(prev =>  updatedStands ? prev : [])
            setReloadStation(prev => prev * Math.random())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ciudades]) 

    useEffect(() => {
        if(modo === 'crear'){
            async function init() {
                if(rolActual){
                    await loadPuestosRol(rolActual?._id);
                    if(rolActual?.unPuesto===true){
                        setPuestos([]);
                        setUnPuestoRol(true);       
                    }else if(rolActual?.unPuesto===false){
                        setPuestos([]);
                        setUnPuestoRol(false);       
                    }
                }
            }
            init()
        }if(modo !== 'crear'){
            async function init() {
                if(rolActual){
                    if(rolActual?.unPuesto===true){  
                        setUnPuestoRol(true);  
                        const res = await  getPuesto(data.puestos[0]);
                        if(res.error){
                            setSnackbarProps("e" + "Error al consultar el puesto asignado")  
                        }else{
                            console.log(res)
                            setPuestos(res.data);
                        }       
                    }else if(rolActual?.unPuesto===false){
                        setUnPuestoRol(false);  
                        const res = await  getPuesto(data.puestos); 
                        if(res.error){
                            setSnackbarProps("e" + "Error al consultar el puesto asignado")  
                        }else{
                            console.log(res)
                            setPuestos(res.data);
                        }    
                    }
                    console.log(rolActual?.unPuesto)
                }
            }
            init()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolActual]);

    useEffect(() => {
        const {user} = isAuthenticated()
        setConfirmacionDocumento(false);
        setDocuementoToValidate('')
        setIsAdmin(user.role == 2)
        setIsDigitacionManualCheck(user?.isDigitacionManual || false)
        setReloadStation(0)
        setReloadCities(0)
        setDepartamentos([])
        setServiciosSelected([])        
        setProyectos(null)
        setCiudades([])
        setPuestos([])
        getDptoMunGerente();
        formik.handleReset();
        setEntregaCheck(false);
        setRecolectaCheck(false);
        setRoleSelected()
        loadEmpresaOperadora()
        setEmpresaSelected()
        setEOselected()
        setGerenteSelected()
        setTipoPagoSelected(tiposPago[0]._id)
        setRolActual(null)
        loadDataRol()
        loadCompanies()
        loadDataGerentes()
        setDepartamentoFacturacion([])
        setCiudadFacturacion([])
        setDocumentType('C.C')
        setSucursalGasto(null)

        if(modo !== 'crear'){            
            if(isAdmin){
                setGerenteSelected(data.gerente._id);
            }
            formik.setFieldValue("documento", data.documento);
            formik.setFieldValue("firstLastname", data.firstLastname);
            formik.setFieldValue("secondLastname", data.secondLastname);
            formik.setFieldValue("firstName", data.firstName);
            formik.setFieldValue("secondName", data?.secondName || '');
            formik.setFieldValue("nacimiento", data.nacimiento);
            formik.setFieldValue("direccion", data.direccion);
            formik.setFieldValue("telefono", data.telefono);
            formik.setFieldValue("tipoSangre", data.tipoSangre);
            formik.setFieldValue("informacionAdicional", data.informacionAdicional);            
            formik.setFieldValue("email", data?.email ?? '');
            formik.setFieldValue("sexo", data?.sexo ?? '');             
            formik.setFieldValue("emailConfirm", data?.email ?? '');            
            formik.setFieldValue("documentType", data?.documentType ?? '');            

            setEOselected(data?.empresaOperadora || '')
            setTipoContribuidorSelected(data.tipo_contribuidor)
            setRoleSelected(data.rol ? data.rol._id:'')
            
            setEmpresaSelected(data.empresa)
            setDepartamentos(data.departamentos)
            setCiudades(data.ciudades)
            setPuestos(data.puestos)
            setEntregaCheck(data.entrega)
            setRecolectaCheck(data.recolecta)
            setCADCheck(data.CAD)
            setProyectos(data?.proyectos || [])
            setServiciosSelected(data?.servicios || [])
            setTipoPagoSelected(data.tipoPago?._id || '');                    
            setDepartamentoFacturacion(data.departamentoFacturacion?._id);            
            setCiudadFacturacion(data.ciudadFacturacion?._id);
            setProveedorSelected(data.proveedorPagos?._id);

            setRolActual(data.rol ? data.rol:'');
            setDocumentType(data?.documentType ?? 'C.C');
            setSucursalGasto(data?.sucursalGasto ?? null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit = async (values) => {
        if(isSubmitting.current) return;

        isSubmitting.current = true;
        if(rolActual){
            if(!rolActual.puestoObligatorio ||  (rolActual.puestoObligatorio && puestos.length > 0) || (rolActual.puestoObligatorio && puestos._id)){
                if(proyectos){
                    let canCreateUser = true        
                    if(isDigitacionManualCheck) 
                        canCreateUser = values.documento == documentoToValidate 
                        if(selectedFile || data?.rutUrl){ //RUT es obligatorio
                            if(canCreateUser){
                                const {user} = isAuthenticated();
                                await changeTipoDigitacionState(isDigitacionManualCheck)
                                refreshDataSession({...user, isDigitacionManual: isDigitacionManualCheck})
                                setConfirmacionDocumento(false);
                                showLoader();
                                isSubmitting.current = false;
                                
                                //Cargar o Editar RUT
                                if(modo === 'crear' && selectedFile){
                                    const urlFileRut = await uploadFile(selectedFile);
                                    values.rutUrl = urlFileRut;
                                }else if(modo === 'editar' && selectedFile){
                                    const urlFileRut = await uploadFile(selectedFile);
                                    values.rutUrl = urlFileRut;
                                }else if(modo === 'editar' && !selectedFile ){
                                    values.rutUrl = data?.rutUrl;
                                }

                                //Generar Codigo para activar cuenta
                                const randomNumber = Math.floor(Math.random() * 10000000000);
                                const formattedNumber = randomNumber.toString().padStart(10, "0");
                            
                                //Valdiar Acaaa
                                values.estadoActivacion=(parseInt(values.documento)*80)+"C"+formattedNumber;
                                //Se verifica el número de cedula del supervisor, que no contenga ceros iniciales
                                values.documento = documentType === 'P' ? values.documento :  parseInt(values.documento);
        
                                values.password = values.documento;
                                values.evento = idEvento;
                                values.entrega = entregaCheck;
                                values.recolecta = recolectaCheck;
                                values.CAD = CADCheck;
                                values.tipo_contribuidor = tipoContribuidorSelected;
                                values.firstLastname = corregirEspaciado(values.firstLastname);   
                                values.secondLastname = corregirEspaciado(values.secondLastname); 
                                values.rol = roleSelected;
                                values.empresa = empresaSelected;
                                values.departamentos = departamentos;
                                values.ciudades = ciudades;
                                values.puestos = puestos;
                                values.documentType = documentType
                                values.proyectos = proyectos;
                                values.empresaOperadora = EOselected;        
                                values.servicios = serviciosSelected;
                                values.tipoPago = tipoPagoSelected;
                                values.rolCompleto = rolActual
                                values.departamentoFacturacion = departamentoFacturacion;
                                values.ciudadFacturacion = ciudadFacturacion;
                                values.sucursalGasto = sucursalGasto

                                const respuestaGerente= await getGerenteCiudad(idEvento, ciudades[0] )

                                if(respuestaGerente.error){
                                    setSnackbarProps("e" + respuestaGerente.error);
                                    }else if(!respuestaGerente.data.gerente){
                                    setSnackbarProps("e" + "La sucursal no tiene un gerente asignado");
                                    }else{
                                    //Se extrae el nombre del rol seleccionado
                                    values.gerente = respuestaGerente.data.gerente._id;
                                    var textRol = "";
                                    for(var i = 0; i < rolesEvento.length; i++){
                                        if(roleSelected == rolesEvento[i]._id){
                                            textRol = rolesEvento[i].name;
                                        }
                                    }
            
                                    var procesarSolicitud = true;
                                    if(textRol.toUpperCase().indexOf("MOTORIZADO") != -1 || textRol.toUpperCase().indexOf("VEHÍCULO") != -1){
                                        //Se valida que el campo de informacionAdicional no este vacío
                                        if(values.informacionAdicional == ""){
                                            procesarSolicitud = false;
                                        }else{
                                            procesarSolicitud = true;
                                        }
                                    }
            
                                    if(procesarSolicitud){
                                        let res = {}
                                        if(modo === 'crear'){
                                            res = await createSupervisor(values, user?.role);
                                        }else if(modo === 'editar'){
                                            if(data?.email===values.email){
                                                values.estadoActivacion=data?.estadoActivacion;
                                            }
                                            values.id = data._id
                                            values.role = user?.role
                                            values.proveedorPagos = proveedorSelected
                                            res = await updateSupervisor(values)
                                        }
                                    
                                        if (res.error){
                                            setSnackbarProps("e" + res.error);
                                        }else{
                                            setSnackbarProps("s" + res.data.message);
                                            if(modo=== 'crear'){
                                                await confirmActivateEmail(values.email, `${values.firstName} ${values.firstLastname}`, res.data._id);
                                                setSnackbarProps("s" + "Se ha enviado un correo de verificación para validar el supervisor");
                                            }else if (data?.email!==values.email && modo==="editar"){
                                                await confirmActivateEmail(values.email, `${values.firstName} ${values.firstLastname}`, data._id );
                                                setSnackbarProps("s" + "Se ha enviado un correo de verificación para validar el supervisor");
                                            }
                                            //borrar campos
                                            resetForm();
                                        }
                                    }else{
                                        setSnackbarProps("e" + "Para los usuarios motorizados ó que tengan asociado un vehículo, el campo información adicional es obligatorío y debe incluir la placa del vehículo.");
                                    } 
                                }
                            }else{
                                setSnackbarProps("e" + "Los documentos no concuerdan");
                            }
                            hideLoader(); 
                        }else{
                        setSnackbarProps("e" + "Debe Cargar el RUT");
                        }  
                }else{
                    setSnackbarProps("e" + "Debe asignarse un proyecto valido");
                } 
            }else{
                setSnackbarProps("e" + "Según el rol solecciónado, debe seleccionar minimo un puesto");
            }
        }else{
            setSnackbarProps("e" + "Rol es requerido para el registro");
        }
        isSubmitting.current = false;
    }

    const formik = useFormik({
        initialValues:{
            documento: "",
            firstLastname: "",
            secondLastname: "",
            firstName: "",
            secondName: "",
            tipo_contribuidor: false,
            direccion: "",
            telefono: "",
            departamentos: [],
            ciudades: [],
            puestos: [],
            nacimiento: "",
            rol: "",
            tipo_servicio: "",
            empresa: "",
            password: "",
            evento: "",
            entrega: false,
            recolecta: false, 
            informacionAdicional: "",
            tipoSangre : "",
            email: '',
            emailConfirm: '',
        },
        validationSchema: yup.object().shape({
            firstName: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            firstLastname: yup.string().matches(regex.letrasEspacios, "Este campo solo admite letras y espacios").required("Debes llenar este campo"),
            documento: documentType === 'P' ? yup.string().required("Debes llenar este campo") : yup.string().matches(regex.numeros, "Este campo solo admite números").required("Debes llenar este campo"),
            telefono: yup.string().length(10).matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            nacimiento: yup.string().matches(regex.numeros, "Este campo solo admite numeros").required("Debes llenar este campo"),
            email: yup.string().email("El correo no es valido").required("Debes llenar este campo"),
            emailConfirm: yup.string().email("El correo no es valido").required("Debes llenar este campo").oneOf([yup.ref('email'), null], 'Los correos no coinciden'),
        }),
        onSubmit
    });

    const { handleSubmit, touched, errors, getFieldProps } = formik;
    const direccionProps = getFieldProps("direccion");
    const firstNameProps = getFieldProps("firstName");
    const secondNameProps = getFieldProps("secondName");
    const firstLastnameProps = getFieldProps("firstLastname");
    const secondLastnameProps = getFieldProps("secondLastname");
    const documentoProps = getFieldProps("documento");
    const telefonoProps = getFieldProps("telefono"); 
    const informacionAdicionalProps = getFieldProps("informacionAdicional"); 
    const nacimientoProps = getFieldProps("nacimiento"); 
    const tipoSangreProps = getFieldProps("tipoSangre");     
    const sexoProps = getFieldProps("sexo");   
    const emailProps = getFieldProps("email");
    const emailConfirmProps = getFieldProps("emailConfirm");

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 55,
        height: 34,
        padding: 5,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(28px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 6,
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 20,
          height: 20,
          borderRadius: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 32 / 2,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
    }));

    const formFields = () => (
        <>
            <Box p={1}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h2>Tipo de digitación</h2>
                        <h4>
                            Automática: Cuando se usa lector de cédula
                        </h4>
                        <h4>
                            Manual: No se usa lector de cédula. Cada campo se llena manualmente 
                        </h4>
                    </Grid>
                    <Grid item xs={6} ms={6}>
                        <Box p={1} className="boxContentInput">
                            <div className="leftContentTitle">Tipo de digitación</div>
                            <Stack 
                                className="bolleanButtonCenter"
                                direction="row" 
                                spacing={1} 
                                alignItems="center"
                                name="recoleccion">
                                <Typography>Automática</Typography>
                                <AntSwitch 
                                    checked = {isDigitacionManualCheck}
                                    onChange = {() => setIsDigitacionManualCheck(!isDigitacionManualCheck)}
                                    inputProps={{ 'aria-label': 'ant design' }}
                                />
                                <Typography>Manual</Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <h2>Datos basicos</h2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Tipo de documento</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='documentType'
                            fullWidth
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            label="Tipo de documento *"
                        >
                            <MenuItem value='C.C' selected>Cédula de Ciudadanía (Colombia)</MenuItem>
                            <MenuItem value='C.I'>Cédula de Identidad (Venezuela)</MenuItem>
                            <MenuItem value='P'>Pasaporte (Internacional)</MenuItem>
                        </Select>
                    </Grid>
                    {modo === 'crear'? <>
                        <Grid item xs={12} sm={4}>
                            <InputLabel className='bottomPadding'>Documento *</InputLabel>
                            <TextField
                                size="small"
                                variant="outlined" 
                                name="documento" 
                                placeholder='Documento'
                                fullWidth
                                required
                                inputProps={{ maxLength: 20 }}
                                helperText={touched.documento ? errors.documento : ""}
                                error={touched.documento && Boolean(errors.documento)}
                                {...documentoProps}
                            />
                        </Grid></>
                        :
                        <><Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Documento</InputLabel>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="documento" 
                            placeholder='Documento'
                            fullWidth
                            required
                            disabled
                            inputProps={{ maxLength: 20 }}
                            helperText={touched.documento ? errors.documento : ""}
                            error={touched.documento && Boolean(errors.documento)}
                            {...documentoProps}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={handleButtonEditCedula}>
                                      <Edit />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                        />
                        </Grid></> 
                    }
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Primer apellido</InputLabel>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="firstLastname" 
                            placeholder="Primer apellido"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.firstLastname ? errors.firstLastname : ""}
                            error={touched.firstLastname && Boolean(errors.firstLastname)}
                            {...firstLastnameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="secondLastname" 
                            label="Segundo apellido"          
                            fullWidth                  
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.secondLastname ? errors.secondLastname : ""}
                            error={touched.secondLastname && Boolean(errors.secondLastname)}
                            {...secondLastnameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="firstName" 
                            label="Primer nombre"
                            fullWidth
                            required
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.firstName ? errors.firstName : ""}
                            error={touched.firstName && Boolean(errors.firstName)}
                            {...firstNameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="secondName" 
                            label="Segundo nombre"
                            fullWidth
                            inputProps={{ maxLength: 30 }}
                            helperText={touched.secondName ? errors.secondName : ""}
                            error={touched.secondName && Boolean(errors.secondName)}
                            {...secondNameProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="sexo" 
                            label="Sexo (M/F) (Opcional)"
                            fullWidth    
                            inputProps={{ maxLength: 1 }}
                            helperText={touched.sexo ? errors.sexo : ""}
                            error={touched.sexo && Boolean(errors.sexo)}
                            {...sexoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="nacimiento" 
                            label="Fecha de nacimiento (DDMMAAAA)"
                            required
                            fullWidth    
                            type="number"                        
                            inputProps={{ maxLength: 8 }}
                            helperText={touched.nacimiento ? errors.nacimiento : ""}
                            error={touched.nacimiento && Boolean(errors.nacimiento)}
                            {...nacimientoProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="tipoSangre" 
                            label="Tipo Sangre (Opcional)"
                            fullWidth
                            inputProps={{ maxLength: 2 }}
                            helperText={touched.tipoSangre ? errors.tipoSangre : ""}
                            error={touched.tipoSangre && Boolean(errors.tipoSangre)}
                            {...tipoSangreProps}
                        />
                    </Grid>     
                    <Grid item xs={12} sm={12}></Grid>               
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="direccion" 
                            label="Dirección"
                            fullWidth                            
                            inputProps={{ maxLength: 40 }}
                            helperText={touched.direccion ? errors.direccion : ""}
                            error={touched.direccion && Boolean(errors.direccion)}
                            {...direccionProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="telefono" 
                            label="Celular"
                            fullWidth
                            required
                            inputProps={{ maxLength: 24 }}
                            helperText={touched.telefono ? errors.telefono : ""}
                            error={touched.telefono && Boolean(errors.telefono)}
                            {...telefonoProps}
                        />
                    </Grid>    
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="email" 
                            label="Correo electrónico"
                            fullWidth
                            required
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            {...emailProps}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="emailConfirm" 
                            label="Confirmar correo electrónico"
                            fullWidth
                            required
                            helperText={touched.emailConfirm ? errors.emailConfirm : ""}
                            error={touched.emailConfirm && Boolean(errors.emailConfirm)}
                            {...emailConfirmProps}
                        />
                    </Grid>                                   
                    <Grid item xs={12}>
                        <h2>Datos tributarios</h2>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel className='bottomPadding'>Tipo de contribuyente</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='tipo_contribuidor'
                            fullWidth
                            value={tipoContribuidorSelected}
                            onChange={(e) => setTipoContribuidorSelected(e.target.value)}
                            label="Tipo de contribuidor *">
                            <MenuItem value={true} selected>Natural</MenuItem>
                            {/*<MenuItem value={false}>Juridica</MenuItem>*/}
                        </Select>
                    </Grid>     
                    <Grid item xs={12} sm={6}>
                        <InputLabel className='bottomPadding'>Tipo de pago *</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='tipoPago'
                            value={tipoPagoSelected ?? ""}
                            onChange={(e) => setTipoPagoSelected(e.target.value)}
                            fullWidth
                            label="Tipo de pago *">
                            {
                                tiposPago.length > 0 ?
                                tiposPago.map( i => 
                                        <MenuItem value={i._id} key={i._id + 1}>{i.name}</MenuItem>
                                    )
                                : <MenuItem disabled key={1}>No hay tipos de pago disponibles</MenuItem>
                            }
                        </Select>
                    </Grid>           
                    { modo !== 'editar' ?
                        <Grid item xs={12} sm={6}>
                            <InputLabel className='bottomPadding'>Rol</InputLabel>
                            <Select
                                className='smallClassSelect'
                                variant="outlined" 
                                name='rol'
                                disabled={modo == 'editar'}
                                value={roleSelected??""}
                                defaultValue=''
                                onChange={(e) => {
                                    setRoleSelected(e.target.value)
                                }}
                                fullWidth
                                label={"Rol *"}>
                                {   rolesEvento.length !== 0 ?
                                        rolesEvento.map( item => 
                                            <MenuItem
                                                value={item._id}
                                                name={item.name}
                                                key={item._id + 1}
                                                style={{whiteSpace: 'break-spaces'}}
                                                onClick={() => setRolActual(item)}
                                            >{ item.name + " (" + (item.valorDia? item.valorDia + " valor a pagar por día) ":"Pago variable, definido en la asignación del rol)") }
                                            </MenuItem>
                                        )
                                    : <MenuItem disabled key={1}>No hay roles</MenuItem>
                                }
                            </Select>
                        </Grid>       
                        : 
                        <>
                            <Grid item xs={12} sm={6}>
                                <InputLabel className='bottomPadding'>Rol</InputLabel>
                                <Select
                                    className='smallClassSelect'
                                    variant="outlined" 
                                    name='rol'
                                    disabled
                                    value={roleSelected??""}
                                    defaultValue=''
                                    onChange={(e) => {
                                        setRoleSelected(e.target.value)
                                    }}
                                    fullWidth
                                    label={"Rol *"}>
                                    {   rolesEvento.length !== 0 ?
                                            rolesEvento.map( item => 
                                                <MenuItem
                                                    value={item._id}
                                                    name={item.name}
                                                    key={item._id + 1}
                                                    style={{whiteSpace: 'break-spaces'}}
                                                    onClick={() => setRolActual(item)}
                                                >{ item.name + " (" + (item.valorDia? item.valorDia + " valor a pagar por día) ":"Pago variable, definido en la asignación del rol)") }
                                                </MenuItem>
                                            )
                                        : <MenuItem disabled key={1}>No hay roles</MenuItem>
                                    }
                                </Select>
                            </Grid>
                        </>
                    }    
                    <Grid item xs={12} sm={6}>
                        <InputLabel className='bottomPadding'>Sucursal responsable de gasto</InputLabel>
                        <AutocompleteFromEntity
                            size="small"
                            value={sucursalGasto}
                            enableCreation={false}
                            label="Sucursal responsable de gasto"
                            placeholder="Sucursal responsable de gasto"
                            entity="Sucursal"
                            field="name"
                            setObject={(objs) => {
                                setSucursalGasto(objs?._id);
                            }}
                            condition={{delete:false}}
                            multiple={false}
                            selectAll={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Empresa a la que pertenece el usuario *</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='empresa'
                            value={empresaSelected ?? ""}
                            onChange={(e) => setEmpresaSelected(e.target.value)}
                            fullWidth
                            label="Empresa *">
                            {
                                empresas.length > 0 ?
                                    empresas.map( (i) => <MenuItem value={i._id} key={i._id + 1}>{i.name}</MenuItem>)
                                    : 
                                    <MenuItem disabled key={1}>No hay empresas</MenuItem>
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Empresa operadora a la cual se asocia el pago *</InputLabel>
                        <Select
                            className='smallClassSelect'
                            variant="outlined" 
                            name='empresaOperadora'
                            value={EOselected ?? ""}
                            onChange={(e) => setEOselected(e.target.value)}
                            fullWidth
                            label="Empresa Operadora *">
                            {
                                empresasOperadoras.length > 0 ?
                                    empresasOperadoras.map( (i) => <MenuItem value={i._id} key={i._id + 1}>{i.codigo} - {i.name}</MenuItem>)
                                    : 
                                    <MenuItem disabled key={1}>No hay empresas operadoras para este evento</MenuItem>
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel className='bottomPadding'>Proyectos empresa operadora *</InputLabel>
                        <AutocompleteFromEntity
                            size="small"
                            value={proyectos}
                            enableCreation={false}
                            label="Proyectos de empresa operadora"
                            placeholder="Seleccione los proyectos asignados"
                            entity="Proyecto"
                            field="name"
                            setObject={(objs) => {
                                setProyectos(objs);
                            }}
                            condition={{delete:false, empresaOperadora: (EOselected == "" ? null : EOselected)}}
                            multiple={false}
                            reloadFlag={EOselected}
                            selectAll={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <h2>Asignación de puestos</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>El primer municipio seleccionado, determina el impuesto RETEICA que se aplicará.</h4>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutocompleteFromEntity
                            size="small"
                            value={departamentos}
                            enableCreation={false}
                            label="Departamentos"
                            placeholder="Seleccione los departamentos asignados"
                            entity="Departamento"
                            field="name"
                            setObject={(objs) => {
                                setDepartamentos(objs);
                                setReloadCities(objs.length);                                
                            }}
                            condition={
                                isAdmin ?
                                {delete: false} :
                                {_id:{$in:gerente?.departamentos},delete:false}
                            }
                            multiple={true}
                            selectAll={false}
                            showOtherField={{field: 'codigo', show: true}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={6}>
                        <AutocompleteFromEntity
                            size="small"
                            value={ciudades}
                            enableCreation={false}
                            label="Ciudades"
                            placeholder="Seleccione las ciudades asignadas"
                            entity="Ciudad"
                            field="name"
                            setObject={(objs) => {
                                setCiudades(objs);
                                setReloadStation(objs.length);
                            }}
                            condition={
                                isAdmin ?
                                {departamento:{$in:departamentos}, delete:false} : 
                                {_id:{$in:gerente?.ciudades},departamento:{$in:departamentos},delete:false}
                            }
                            multiple={true}
                            selectAll={false}
                            reloadFlag={reloadCities}      
                            showOtherField={{field: 'codigo', show: true}}                              
                        />
                    </Grid>
                    {unPuestoRol ? 
                            <Grid item xs={12} sm={6}>
                                <AutocompleteFromEntityRegistroStalls
                                    size="small"
                                    value={puestos}
                                    enableCreation={false}
                                    label="Puestos"
                                    placeholder="Seleccione los puestos asignados"
                                    entity="DivipolRegistros"
                                    field="nombreDelPuesto"
                                    setObject={(objs) => {
                                        setPuestos(objs);
                                    }}
                                    condition={{municipio:{$in:ciudades},evento:idEvento, delete:false, _id:{$nin:puestosOcupados}}}
                                    reloadFlag={reloadStation}
                                    multiple={false}
                                    selectAll={false}
                                    showOtherField={{field: 'codigoDivipol', show: true}}                            
                                />
                            </Grid>
                        : 
                            <Grid item xs={12} sm={6}>
                                <AutocompleteFromEntityRegistro
                                    size="small"
                                    value={puestos}
                                    enableCreation={false}
                                    label="Puestos"
                                    placeholder="Seleccione los puestos asignados"
                                    entity="DivipolRegistros"
                                    field="nombreDelPuesto"
                                    setObject={(objs) => {
                                        setPuestos(objs);
                                    }}
                                    condition={{municipio:{$in:ciudades},evento:idEvento, delete:false, _id:{$nin:puestosOcupados}}}
                                    reloadFlag={reloadStation}
                                    multiple={true}
                                    selectAll={false}
                                    showOtherField={{field: 'codigoDivipol', show: true}}                            
                                />
                            </Grid>
                        }
                    <Grid item xs={12}></Grid>
                    {
                        modo == 'editar' ?
                        <>
                            <Grid item xs={12}>
                                <h2>Edición de departamento y municipio de facturación</h2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <AutocompleteFromEntity
                                size="small"
                                value={departamentoFacturacion}
                                enableCreation={false}
                                label="Departamento de Facturación"
                                placeholder="Seleccione el departamento de facturación"
                                entity="Departamento"
                                field="name"
                                setObject={(objs) => {
                                    setDepartamentoFacturacion(objs);    
                                    setReloadCiudadFacturacion(reloadCiudadFacturacion + 1)
                                }}
                                condition={
                                    isAdmin ?
                                    {delete: false} :
                                    {_id:{$in:gerente?.departamentos},delete:false}
                                }
                                multiple={false}
                                selectAll={false}
                            />
                        </Grid>                    
                        <Grid item xs={12} sm={6}>
                            <AutocompleteFromEntity
                                size="small"
                                value={ciudadFacturacion}
                                enableCreation={false}
                                label="Ciudad de Facturación"
                                placeholder="Seleccione la ciudad de facturación"
                                entity="Ciudad"
                                field="name"
                                setObject={(objs) => {
                                    setCiudadFacturacion(objs);
                                }}
                                condition={
                                    isAdmin ?
                                    {departamento:{$in:[departamentoFacturacion]}, delete:false} : 
                                    {_id:{$in:gerente?.ciudades},departamento:{$in:[departamentoFacturacion]},delete:false}
                                }
                                multiple={false}
                                selectAll={false}
                                reloadFlag={reloadCiudadFacturacion}                                   
                            />
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel className='bottomPadding'>Proveedor de pago</InputLabel>
                            <Select
                                className='smallClassSelect'
                                variant="outlined" 
                                name='rol'
                                value={proveedorSelected ?? ''}
                                defaultValue=''
                                onChange={(e) => {
                                    setProveedorSelected(e.target.value)
                                }}
                                fullWidth
                                label="Proveedor de pago *">
                                {   proveedores.length !== 0 ?
                                        proveedores.map( item => 
                                            <MenuItem
                                                value={item._id}
                                                name={item.name}
                                                key={item._id + 1}
                                                style={{whiteSpace: 'break-spaces'}}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        )
                                    : <MenuItem disabled key={1}>No hay proveedores de pago</MenuItem>
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        </>
                        : <></>
                    }
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel 
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'left'}}
                            label="ENTREGA: El usuario tendrá acceso a la aplicación móvil y estará habilitado para ENTREGA de todos los productos disponibles en el evento."
                            control={
                                <Checkbox
                                    checked={entregaCheck}
                                    onChange={() => {setEntregaCheck(!entregaCheck)}}
                                    size='medium'
                                    color='primary'
                                    aria-label='Entrega'
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid> 
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel 
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'left'}}
                            label="RECOLECCIÓN: El usuario tendrá acceso a la aplicación móvil y estará habilitado para RECOLECCIÓN de todos los productos disponibles en el evento."
                            control={
                                <Checkbox
                                    checked={recolectaCheck}
                                    onChange={() => {setRecolectaCheck(!recolectaCheck)}}
                                    size='medium'
                                    color='primary'
                                    aria-label='Recolecta'
                                    inputProps={{ 'aria-label': 'controlled' }}/>                            
                            }
                        />
                    </Grid>  
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel 
                            id="demo-simple-select-required-label"
                            style={{textAlign: 'left'}}
                            label="ENTREGA E-14 A CAD/PD: El usuario tendrá acceso a la aplicación móvil y estará habilitado para ENTREGA E-14 A CAD/PD"
                            control={
                                <Checkbox
                                    checked={CADCheck}
                                    onChange={() => {setCADCheck(!CADCheck)}}
                                    size='medium'
                                    color='primary'
                                    aria-label='CAD'
                                    inputProps={{ 'aria-label': 'controlled' }}/>                            
                            }
                        />
                    </Grid>                                            
                    {/*
                    <Grid item xs={6}>listo
                        <AutocompleteFromEntity
                            value={serviciosSelected}
                            enableCreation={false}
                            label="Seleccione uno a más servicios"
                            placeholder="Seleccione uno o más servicios"
                            entity="Servicio"
                            field="name"
                            setObject={(objs) => {
                                console.log("Servicios seleccionados");
                                console.log(objs);
                                setServiciosSelected(objs);
                            }}
                            condition={{delete:false}}
                            multiple={true}
                            selectAll={false}
                        />
                    </Grid>
                    */}
                    {
                        isAdmin ? 
                        <>
                            {/* <Grid item xs={12}>
                                <h2>Gerente responsable</h2>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className='bottomPadding'>Gerente *</InputLabel>
                                <Select
                                    className='smallClassSelect'
                                    variant="outlined" 
                                    name='gerente'
                                    value={gerenteSelected}
                                    defaultValue=''
                                    onChange={(e) => {setGerenteSelected(e.target.value);}}
                                    fullWidth
                                    label="Gerente *">
                                    {
                                        gerentes.length > 0 ?
                                            gerentes.map( i => 
                                                <MenuItem value={i._id} key={i._id + 1}>{`${i.documento} - ${i.name} ${i.firstLastname}`}</MenuItem>
                                            )
                                        : <MenuItem disabled key={1}>No hay gerentes para la empresa de este administrador</MenuItem>
                                    }
                                </Select>
                            </Grid> */}
                        </> : <></>
                    }
                    <Grid item xs={12}>
                        <h2>Información adicional (opcional)</h2>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel className='bottomPadding'>Información adicional, aquí se puede registrar datos como placas de vehículos asociados ó datos adicionales.</InputLabel>
                        <TextField
                            size="small"
                            variant="outlined" 
                            name="informacionAdicional" 
                            fullWidth
                            inputProps={{ min: 0, max: 10 }}
                            helperText={touched.informacionAdicional ? errors.informacionAdicional : ""}
                            error={touched.informacionAdicional && Boolean(errors.informacionAdicional)}
                            {...informacionAdicionalProps}
                        />
                    </Grid>  
                    <Grid item xs={12}>
                            <Box p={0}>
                                <Paper elevation={2}>
                                    <Grid item xs={12}>
                                        <Box className="panelUploadFiles">    
                                            {/* <LinearProgress 
                                                className="sizeProgressBar"
                                                variant="determinate" 
                                                value={progressUpload} /> */}
                                            <Box p={1}>
                                                <h3>Subida de Archivo RUT (PDF)</h3>
                                            </Box>  
                                            <Box p={1}>
                                                <h4>{selectedFile? <>{selectedFile.name}</>: <>{(modo === 'editar') ? <>{data?.rutUrl ? <>CARGADO</>:<><>SIN RUT</></>}</>:null}</>}</h4>
                                            </Box>                   
                                            <Box p={1}>
                                            <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    color="primary"
                                                    startIcon={<CloudUploadIcon />}
                                                    onClick={()=> {
                                                        //Call triger in input file
                                                        inputFileRef.current.click();
                                                    }}>
                                                    Seleccione el archivo Rut
                                            </Button>

                                                <input ref={inputFileRef} type="file" onChange={handleFileChange} accept=".pdf" id="fileInput" className="hidenPanel" />                        
                                            </Box>      
                                        </Box>                    
                                    </Grid>
                                </Paper>
                            </Box>    
                        </Grid> 
                </Grid>
            </Box>
        </>
    )

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                <form onSubmit={handleSubmit} autoComplete="off" id='formulario-registro'>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={closeClear} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {
                                    modo === 'crear' ? 
                                    `Registra un supervisor para evento: ${eventoName}`
                                    : `Edita un supervisor para evento: ${eventoName}`
                                }
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formFields()} 

                        {/* Dialogo que incluye el boton de confirmacion */}                    
                    </DialogContent>                     
                    <Dialog 
                        open={confirmacionDocumento} 
                        TransitionComponent={Transition}
                    >
                        {
                            isDigitacionManualCheck ?
                            <>
                                <DialogTitle id="alert-dialog-title">Vuelva a digitar el documento del usuario</DialogTitle>
                                    <DialogContent>
                                        <Grid item xs={12} className="widthDialogConfirmacion">
                                        <TextField
                                            size="small"
                                            autoFocus
                                            variant="outlined" 
                                            name="documentoToValidate" 
                                            label="Documento"
                                            fullWidth
                                            required
                                            value={documentoToValidate}
                                            onChange={(e) => setDocuementoToValidate(e.target.value)}
                                        />
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions style={{textAlign: 'right'}}>
                                        <Button onClick={()=>{setConfirmacionDocumento(false)}} color="secondary">
                                            Cancelar
                                        </Button>
                                        <Button form='formulario-registro' type="submit" color="primary" startIcon={<SaveIcon />}>
                                            Confirmar
                                        </Button>
                                </DialogActions>
                            </> :
                            <>
                                <DialogTitle id="alert-dialog-title">Confirma que este es el número de documento del usuario</DialogTitle>
                                    <DialogContent>
                                        <Grid item xs={12} className="widthDialogConfirmacion">
                                            <h1> {formik.values.documento}</h1>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions style={{textAlign: 'right'}}>
                                        <Button onClick={()=>{setConfirmacionDocumento(false)}} color="secondary">
                                            No es correcto
                                        </Button>
                                        <Button form='formulario-registro' type="submit" color="primary" startIcon={<SaveIcon />} >
                                            Si, el documento es correcto
                                        </Button>
                                </DialogActions>
                            </>
                        }
                        
                    </Dialog>

                </form>             

                <Box p={4}>
                    <Button 
                        fullWidth                        
                        color="primary" 
                        variant='contained'
                        onClick={()=>{setConfirmacionDocumento(true)}}
                        startIcon={<SaveIcon />}>
                        Confirmar número de documento
                    </Button> 
                </Box>   
            </Dialog>

            {modalValidarCambiarCedula? 
                <DialogValidarCambiarCedula  
                title={`Modificar cédula ${data?.documento} de ${data?.firstName} ${data?.firstLastname} por:`}
                content='¿Deseas validar todos los conceptos de este usuario?'
                modificarCedula={handleCambiarCedula}
                open={modalValidarCambiarCedula}
                handleClose={closeModalCedula}
                documento = {data?.documento}
                values= {data}
                newCedula= {newCedula}
                />
            : null
            }

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}

    


