import React, { useState, useEffect, useRef} from 'react';
import { procesarArchivoReteica } from '../../../../core/apiReteica';
import { SelectorEvento } from '../../../presupuesto/SelectorEvento';

// Componentes
import { 
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    Link,
    Grid, 
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    makeStyles,
    Box,
    Paper,
    LinearProgress
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { getEventos } from '../../../../core/apiEventos';

// Custom components
import { showLoader, hideLoader } from '../../../../../src/components/shared/Loader';
import ShowAlert from '../../../../../src/components/shared/Snackbar';
import "../../../../App.css";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CargueReteica ({open, close, refreshTable})
{
    const classes = useStyles();
    const inputFileRef = useRef();

    /* Snackbar */
    const [snackbarProps, setSnackbarProps] = useState();
    const [progressUpload, setProgressUpload] = useState(0);
    const [fileSelect, setFileSelect] = useState(null);
    const [eventoSeleccionado, setEventoSeleccionado] = useState(null);
    const [eventos, setEventos] = useState([]);
    
    /* Textos del título/botón del formulario */

    const procesarReteica = async () => {
        if(fileSelect){
            showLoader();

            const res = await procesarArchivoReteica(fileSelect, eventoSeleccionado, setProgressUpload);
    
            if (res.error){
                setSnackbarProps("e" + res.error);                
                hideLoader();
            }else{      
                setSnackbarProps("s" + res.data.message);                      
                hideLoader();
                close();
            }     
            
            refreshTable();              
        }else{
            setSnackbarProps("e" + "Debe adjuntar un documento formato excel, o seleccionar el evento objetivo del cargue, con la información de los impuestos por municipio, en formato código divipol municipio, valor de 0 a 100.");
        }
    }

    const closeClear = () => {
        setFileSelect(null);
        setFileSelect(null);
        setProgressUpload(0)
        close();
    }

    const loadEventos = async () => {
        showLoader();
        const res = await getEventos()    
        if (res.error){
            setSnackbarProps("e" + res.error);
            setEventos([]);
        }else{
            setEventos(res.data);
            setEventoSeleccionado(res.data[0]._id);
        }
        hideLoader(false)
    }

    useEffect(() => {
        setFileSelect(null);        
        loadEventos();
    }, []);

    return (
        <>
            <Dialog 
                open={open} 
                fullScreen
                aria-labelledby="form-dialog-title"
                TransitionComponent={Transition}>
                
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={closeClear} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            RETEICA
                        </Typography>
                        <Hidden xsDown>
                            <Button 
                                color="inherit" 
                                endIcon={<SaveIcon />}
                                onClick={() => {
                                    procesarReteica();
                                }}>
                                Procesar archivo adjunto
                            </Button>
                        </Hidden>                            
                        <Hidden smUp>
                            <IconButton color="inherit">
                                <SaveIcon />
                            </IconButton>
                        </Hidden>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box p={2}>
                                <Paper elevation={2}>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Subida masiva de información, RETEICA</Typography>
                                    </Box>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Instrucciones</Typography>  
                                    </Box>
                                    <Box display="flex" p={2}>
                                        <div>
                                        <Typography>1. Descargar la plantilla que ha sido generada con base a la configuración realizada.</Typography>
                                        <Typography>2. Diligenciarla con la información requerida.</Typography>
                                        <Typography>3. Seleccione el evento al que desea hacer el cargue de RETEICA.</Typography>
                                        <Typography>4. Carga el archivo dando click en el botón "Seleccionar archivo"</Typography>
                                        <Typography>Nota: Guiarse estrictamente del formato de la plantilla para evitar errores en la lectura de la informacón.</Typography>
                                        <Typography>Nota: Tenga en cuenta que este cargue actualizará todos los impuestos de los municipios anexos al formato, los municipios no incluidos no se verán afectados.</Typography>
                                        <Typography>Nota: Los pagos ya realizados en el evento, no se verán afectados por este ajuste.</Typography>
                                        </div>
                                    </Box>
                                    <Box display="flex" p={2} justifyContent="space-between">
                                        <Typography variant= 'h6'>Descargar plantilla <Link href="./plantillas/reteica.xlsx">aquí</Link></Typography>  
                                    </Box>

                                    <Box p={2}>
                                        <Box>
                                            <Typography>Evento al que se cargarán las tarifas de impuesto RETEICA especificadas.</Typography>
                                        </Box>
                                        <Box>
                                            <SelectorEvento
                                                defaultId={eventos[0]?._id}
                                                setEventos={setEventoSeleccionado}
                                                eventos={eventos}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>  
                        </Grid>                
                        <Grid item xs={12}>
                            <Box p={2}>
                                <Paper elevation={2}>
                                    <Grid item xs={12}>
                                        <Box className="panelUploadFiles">    
                                            <LinearProgress 
                                                className="sizeProgressBar"
                                                variant="determinate" 
                                                value={progressUpload} />
                                            <Box p={1}>
                                                <h3>Subida de archivo divipol</h3>
                                            </Box>  
                                            <Box p={1}>
                                                <h4>{fileSelect? fileSelect.name:""}</h4>
                                            </Box>                   
                                            <Box p={1}>
                                                <Button 
                                                    fullWidth
                                                    variant="contained" 
                                                    color="primary"
                                                    startIcon={<CloudUploadIcon />}
                                                    onClick={()=> {
                                                        //Call triger in input file
                                                        inputFileRef.current.click();
                                                    }}>
                                                    Seleccione el archivo que contiene los registros de impuestos.
                                                </Button>

                                                <input  type="file" 
                                                        name="file" 
                                                        ref={inputFileRef} 
                                                        onChange={(e)=>{
                                                            setFileSelect(e.target.files[0])
                                                        }} 
                                                        className="hidenPanel"/>                         
                                            </Box>      
                                        </Box>                      
                                    </Grid>
                                </Paper>
                            </Box>    
                        </Grid>
                    </Grid>                    
                </DialogContent>               
            </Dialog>

            {ShowAlert(snackbarProps, setSnackbarProps)}
        </>
    );
}

    


